<template>
  <div class="channel_content">
    <div class="channel_search fixed">
      <van-search
        v-model="searchValue"
        placeholder="请输入活码名称"
        shape="round"
        @search="onSearch"
      />
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onDownRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        v-if="!isShowEmpty"
      >
        <div class="codeList_content">
          <div
            class="code_content"
            @click="handleCodeJump(item)"
            v-for="(item, index) in codeList"
            :key="index"
          >
            <div class="code_imgContent">
              <img
                v-if="item.group_qr_code"
                :src="item.group_qr_code"
                alt="二维码"
                class="code_img"
              />
              <div class="code_img_empty" v-if="!item.group_qr_code">--</div>
            </div>
            <div class="code_tipContent">
              <div class="code_title">{{ item.group_name }}</div>
              <div class="code_desc">
                <span
                  v-if="![0, 4].includes(item.card_state)"
                  :class="'bgColor' + item.card_state"
                  >{{ stateObj[item.card_state] }}</span
                >
                <div class="code_fontSize">
                  群总人数：{{ item.sum_customer_group_num }}
                </div>
                <div class="code_create code_fontSize">
                  {{ item.created_at }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <van-empty description="没有数据" v-if="list == null" />
    <div class="emptyMsg" v-if="isShowEmpty && !isSearchState">
      <img src="@/assets/images/empty.png" alt="" class="img" />
      <div class="text text1">暂无群组活码</div>
      <div class="text text2">您可以前往电脑端管理台创建~</div>
    </div>
    <empty-page
      :msg="emptyMsg"
      v-if="isShowEmpty && isSearchState"
    ></empty-page>
  </div>
</template>

<script>
import emptyPage from "@/components/emptyPage.vue";
export default {
  name: "channelLiveCode",
  components: { emptyPage },
  data() {
    return {
      codeList: [],
      searchValue: "",
      curState: "",
      list: [],
      page: 0, //页码
      loading: false, //上拉加载
      finished: false, //上拉加载完毕
      refreshing: false, //下拉刷新
      isDown: false,
      stateObj: {
        // 0: "全部类型",
        1: "部分失效",
        2: "全部失效",
        3: "使用中",
        // 4: "草稿",
      },
      isShowEmpty: false,
      isSearchState: false,
    };
  },
  created() {
    this.curState = this.$route.query.type;
  },
  computed: {
    emptyMsg() {
      return this.search_value ? "搜索无结果" : "暂无活码";
    },
  },
  mounted() {},
  //监听
  watch: {
    $route(to, from) {
      if (from.name === "home") {
        this.codeList = [];
        this.loading = false; //上拉加载
        this.refreshing = true; //下拉刷新
        this.isDown = true;
        this.refreshing = true;
        this.onDownRefresh();
      }
    },
  },
  methods: {
    //上拉加载
    onLoad() {
      if (this.refreshing) {
        this.codeList = [];
        this.refreshing = false;
      }
      if (this.isDown) {
        this.page = 1;
      } else {
        this.page++;
      }
      this.getGroupCodeListData();
    },
    getGroupCodeListData() {
      this.$ajax("group_card_list", {
        group_name: this.searchValue || "",
        start_at: "",
        end_at: "",
        page: this.page,
        page_size: 10,
        state: "",
      }).then((res) => {
        if (res.data && res.data.code === 0) {
          let resCodeList = res.data.data.list;
          if (resCodeList.length > 0) {
            if (this.isDown) {
              this.codeList = resCodeList;
              this.loading = false;
              this.isDown = false;
            } else {
              this.codeList = this.codeList.concat(resCodeList);
              this.loading = false;
            }
          } else {
            this.finished = true; //完成加载
          }
          this.$toast.clear();
          this.isShowEmpty = !this.codeList.length ? true : false;
        } else {
          this.$toast("网络错误");
        }
      });
    },
    // 下拉刷新
    onDownRefresh() {
      this.finished = false;
      this.loading = true;
      this.isDown = true;
      this.onLoad();
    },
    setLoading() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
    },
    handleCodeJump(item) {
      //此处需修改标题
      this.$router.push({
        name: "codeDetails",
        query: {
          type: this.curState,
          id: item.group_card_id,
          code_url: item.group_qr_code,
          name: item.group_name,
        },
      });
    },
    onSearch() {
      //搜索重置参数
      this.setLoading();
      console.log(this.searchValue);
      this.isDown = true;
      this.isSearchState = true;
      this.codeList = [];
      this.page = 1;
      this.refreshing = false;
      this.finished = false;
      this.getGroupCodeListData();
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "home" || to.name === "codeDetails") {
      this.searchValue = "";
      this.isShowEmpty = false;
    }
    next();
  },
};
</script>
<style lang="scss" scoped>
.channel_content {
  height: 100%;
  width: 100%;
  .emptyPage {
    height: 100%;
    position: absolute;
    width: 100%;
  }
  .channel_search {
    width: 100%;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 9999;
  }
  .channel_tip {
    height: 0.8rem;
    text-align: center;
    line-height: 0.8rem;
  }
  .codeList_content {
    padding: 0.32rem 0.32rem 0.16rem 0.32rem;
    .code_content {
      padding: 0.24rem 0.32rem;
      margin-bottom: 0.16rem;
      border-radius: 0.16rem;
      border-radius: 0.08rem;
      background-color: #fff;
      display: flex;
      position: relative;
      .code_imgContent {
        flex-shrink: 0;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 0.08rem;
        .code_img {
          width: 1.6rem;
          height: 1.6rem;
          border-radius: 0.08rem;
        }
        .code_img_empty {
          width: 1.6rem;
          height: 1.6rem;
          text-align: center;
          line-height: 1.6rem;
        }
      }
      .code_tipContent {
        margin-left: 0.32rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .code_fontSize {
          font-size: 0.24rem;
          font-weight: 400;
          color: #333333;
          line-height: 0.36rem;
        }
        .code_name_list {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .code_title {
          font-size: 0.28rem;
          font-weight: 400;
          color: #333333;
          line-height: 0.4rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 3.5rem;
        }
        .code_desc {
          display: flex;
          flex-direction: column;
        }
        span {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 0.24rem;
          font-weight: 400;
          line-height: 0.24rem;
          padding: 0.08rem 0.16rem;
          border-radius: 0rem 0.16rem 0rem 0.16rem;
        }
        .code_create {
          color: #999999;
        }
      }
    }
  }
  .bgColor {
    &0 {
      background-color: red;
    }
    &1 {
      background-color: #fff3eb;
      color: #ff781f;
    }
    &2 {
      background-color: #f5f5f5;
      color: #b2b2b2;
    }
    &3 {
      background-color: #ebf1ff;
      color: #105cfb;
    }
    &4 {
      background-color: rgb(252, 152, 153);
    }
  }
  .emptyMsg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    .img {
      width: 1.76rem;
      height: 1.76rem;
    }
    .text {
      text-align: center;
      font-size: 0.32rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.48rem;
      white-space: nowrap;
    }
    .text1 {
      margin-top: 0.32rem;
    }
    .text2 {
      margin-top: 0.12rem;
    }
  }
}
</style>
<style lang="scss">
.channel_content {
  .van-list {
    background: #f5f6f9;
  }
}
</style>
